.player-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
}

.duration {
    display: flex;
    flex-flow: row;
    width: 50%;
    align-items: center;
    justify-content: space-evenly;


    input {
        width: 100%;
        padding: 1rem 0;
        -webkit-appearance: none;
        background: transparent;
        cursor: pointer;
    }

    p {
        padding: 1rem;
    }
}

.player-control {
    width: 30%;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 1rem;
    padding-bottom: 3rem;

    svg {
        cursor: pointer;
    }
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: transparent;
    border: none;
}

input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    background: transparent;
    border: none;
}

.track {
    border: 2px solid #fff;
    border-radius: 1rem;
    width: 100%;
    height: 1rem;
    position: relative;
    overflow: hidden;
}

.animate-track {
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(0%);
    pointer-events: none;
}

@media screen and (max-width: 765px){
    .duration, .player-control { 
        width: 90%;
    }
}