// colors
// #E8E1E8 - OffWhite
// #93A603 - LightGreen
// #5A7302 - MidGreen 
// #2C4001 - DarkGreen
// #9CB0D9 - LightBlue

.object-fit {
    object-fit: cover;
    height: 100%;
}

h2, h3, h4 {
    font-size: 2rem;
}

.maderia-header {
    display: flex;
    flex-flow: column;
    align-items: center;
    color: #2C4001;

    @media only screen and (min-width: 760px) {
        flex-flow: row;
    }

    .header-img {
        width: 100%;

        @media only screen and (min-width: 760px) {
            width: 60%;
        }
    }

    .header-text {
        height: 100%;
        padding: 1rem;
        width: 85%;
        margin: -20% auto 0;
        
        @media only screen and (min-width: 760px) {
            padding: 3rem;
            width: 100%;
            margin: auto -4rem;
        }
    }

    h1 {
        line-height: 1.5rem;
    }
}

.subtitle {
    font-size: 0.7rem;
    line-height: 0.7rem;
    text-transform: uppercase;
    color: #2C4001;
}

.caption-subtitle {
    font-size: 1rem;
    line-height: 0.7rem;
    text-transform: uppercase;
    color: #2C4001;
}

.left-green-border {
    border-left: #5A7302 solid 3px;
    padding-left: 2rem;
     h2 {
        color: #5A7302;
     }
}


.icon-bullets {
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 1rem 0;

    p {
        margin: 0;
    }

    @media only screen and (min-width: 760px) {
        flex-flow: wrap;
        justify-content: space-evenly;
        align-items: flex-start;
        text-align: left;
    }
}



// Background Colors
.light-grey-bg {
    background-color: #fff;
}

.dark-green-bg {
    background-color: #2C4001;
    color: #fff;
}