@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.design-1 {
    //Font Scheme
    .design-header {
        font-family: 'Roboto', sans-serif;
        font-size: 2rem;
        font-weight: 700;
    }
    .design-sub-header {
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        font-weight: 500;
    }
    .design-body {
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        font-weight: 400;
    }

    // Color scheme
    .page-bg{
        background-image: linear-gradient(210deg, #FFFFFF, #F2F2F2 );
    }

    .color-box{
        padding: 2rem 2rem 1rem 1rem;
        margin: 0.2rem;
        border-radius: 10px;
    }
    
    .inverse-text {
        color: #fff;
    }

    .color1 {
        background-color: #2f3b18;
    }
    .color2 {
        background-color: #4e7f1c;
    }
    .color3 {
        background-color: #6d9826;
    }
    .color4 {
        background-color: #9DB03A;
    }
    .color5 {
        background-color: #ECECEC;
    }
    .color6 {
        background-color: #7D7D7D;
    }
    .color7 {
        background-color: #D44338;
    }

    // Button scheme
    .button {
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        font-weight: 700;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        border: none;
    }

    .primary {
        color: #fff;
        background-color: #9DB03A;
        box-shadow: 3px 3px 0 0 #4e7f1c;
    }

    .primary-hover {
        color: #fff;
        background-color: #9DB03A;
    }

    .primary-selected {
        color: #fff;
        background-color: #4e7f1c;
    }

    .primary-disabled {
        color: #7D7D7D;
        background-color: #ECECEC;
        box-shadow: 3px 3px 0 0 #7D7D7D;
    }

    .secondary {
        color: #9DB03A;
        background-color: #ECECEC;
        box-shadow: 3px 3px 0 0 #9DB03A;
    }

    .secondary-hover {
        color: #9DB03A;
        background-color: #ECECEC;
    }

    .secondary-selected {
        color: #4e7f1c;
        background-color: #ECECEC;
        box-shadow: 3px 3px 0 0 #4e7f1c;
    }

    .tertiary {
        color: #9DB03A;
        background-color: transparent;
    }

    .tertiary-hover {
        color: #4e7f1c;
        background-color: transparent;
    }

    .tertiary-selected {
        color: #4e7f1c;
        background-color: transparent;
    }

    .tertiary-disabled {
        color: #7D7D7D;
        background-color: transparent;
    }
}

    