@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.design-2 {
    //Font Scheme
    .design-header {
        font-family: 'Roboto', sans-serif;
        font-size: 2rem;
        font-weight: 700;
    }
    .design-sub-header {
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        font-weight: 500;
    }
    .design-body {
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        font-weight: 400;
    }

    // Color scheme
    .page-bg{
        background-image: linear-gradient(210deg, #FFFFFF, #F2F2F2 );
    }

    .color-box{
        padding: 2rem 2rem 1rem 1rem;
        margin: 0.2rem;
        border-radius: 10px;
    }
    
    .inverse-text {
        color: #fff;
    }

    .color1 {
        background-color: #222831;
    }
    .color2 {
        background-color: #393E46;
    }
    .color3 {
        background-color: #00ADB5;
    }
    .color4 {
        background-color: #E3EBEB;
    }
}