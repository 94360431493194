// Colors
// #949961 - green
// #483e37 - dark slate
// #787061 - light slate
// #b69d86 - biege


.florence-page {
    background-color: #ffffff;
    // Header
    .florence-header {
        background-image: url(../Img/Florence/Header_bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        margin-top: 30%;

        @media only screen and (min-width: 760px) {
            flex-flow: row;
            padding-top: 3%;
            align-items: flex-end;
            margin-top: 0;
        }
    }

    .header-headshot {
        width: 70%;
        margin: -20% 0 20% 0;
        
        img { 
            border: solid 5px #fff;
        }

        @media only screen and (min-width: 760px) {
            margin: 0;
            width: 30%;
        }
    }

    .header-text {
        width: 100%;
        background-color: rgba(182, 157, 134, 0.7);
        padding: 2%;

        @media only screen and (min-width: 760px) {
            width: 50%;
        }
    }

    // coloured text boxes
    .colored-caption {
        margin: 0;
        padding: 0.5rem;
        color: #fff;
        background-color: #787061;
    }

    .green-text-box {
        background-color: #949961;
        padding: 2rem;

        @media only screen and (min-width: 760px) {
            padding: 2rem 5rem;
        }
    }

    .photo-gallery {
        margin: 3%;
        
        img {
            border: #fff 5px solid
        }

        .large-size {
            width: 70%;
        }

        .small-size {
            width: 30%;
        }
    }

    .center-row {
        margin: 5%;
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
        align-items: center;

        @media only screen and (min-width: 760px) {
            flex-flow: wrap;
        }
        
    }

    .end-row {
        margin: 0 5%;
        display: flex;
        flex-flow: wrap;
        justify-content: space-evenly;
        align-items: flex-end;
    }

    .start-row {
        margin: 0 5%;
        display: flex;
        flex-flow: wrap;
        align-items: flex-start;
    }

    // Border
    .vintage-border {
        margin: 5% 2%;
        position: relative;
        border: solid 10px #b69d86;

        &::before {
            content: "";
            background-image: url(../Img/Florence/border.png);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            width: 90%;
            height: 10%;
            top: -10%;
            left: 50%;
            transform: translate(-50%);
        }

        &::after {
            content: "";
            background-image: url(../Img/Florence/border.png);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            width: 90%;
            height: 10%;
            bottom: -10%;
            left: 50%;
            transform: translate(-50%) rotate(180deg);
        }


    }
}

@import "./global_layouts.scss";