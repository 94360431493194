// Basic styling rules 
* {
  margin: 0;
  padding:0;
  box-sizing: border-box;
}

img {
  width: 100%;
  display: block;
}

.wrap-styling {
  display: flex;
  flex-flow: wrap;
}

.main-background-color {
  background-image: linear-gradient(210deg, #cee1e6, #e9eff4);
}

// Loading Screen
.loading-screen {
  display: flex;
  height:100vh;
  align-items: center;
  justify-content: center;
}


/* Button styling */
.styled-btn {
  font-size: 1rem;
  cursor: pointer;
  padding: 0 2.5rem;
  background-color: #282828;
  color: #fff;
  transition: all 0.5s ease;
  font-family: 'Arial';
  text-decoration: none;
  border: none;

  &:hover {
    color: #000;
    background-color: #fff;
  }
}

/* Headers and body text */
body {
  font-family: 'Arial';
  color: #212121;
}

h2 {
  font-size: 2.5rem;
  font-family: 'Arial';
  span{
    font-family: 'Arial';
    font-weight: bold;
    }
}

h3, h4 {
  font-family: 'Arial';
}

p {
  font-size: 1rem;
  margin: 1rem 0;
  line-height: 1.2rem;
  span {
    color: #282828;
  }
}

.space-between {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}


// Nav and Footer styling
.main-footer,
.main-nav {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  padding: 1rem 3rem;
  background: #282828;
  color: #fff;
  text-align: center;
  z-index: 10;

  .contact-icon {
    font-size: 2rem;
    margin: 0 0.5rem;
    padding: 0.2rem;
  }

  a {
    color: #fff;
    text-decoration: none;

    :hover {
      color: #b8b5ca;
    }
  }
}

// Navigation styling
nav {
  position: fixed;
  justify-content: space-between;
  width: 100%;
  top: 0;
  .nav-logo {
    width: 2rem;
  }

  ul {
    list-style: none;
    display: flex;
    flex-flow: wrap;
  }

  li {
    padding: 0 1rem;

    :hover {
      color: #b8b5ca; 
    }
  }
}

// Footer
.main-footer {
  justify-content: center;
}

// Privscy Notice
.privacy-notice {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background: #a5a5a5;
  color: #000;
  text-align: center;

  .warn-icon {
    font-size: 2rem;
    padding: 0.5rem;
  }
}


// imported styles
@import "./aboutpage";
@import "./contact";
@import "./portfolio";