.library {
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
    padding: 6rem 0.5rem 0.5rem 0.5rem;
    overflow-y: scroll;
    transform: translateX(-100%);
    transition: all 0.5s ease;
    background-color: #fff;
    opacity: 0;

    h2 {
        margin: 0.5rem;
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1rem;
        font-weight: 600;
    }
    
    h4 {
        font-size: 1rem;
        font-weight: 500;
    }

    .library-song {
        display: flex;
        flex-flow: row;
        align-items: center;
        padding: 1rem;
        cursor: pointer;

        &:hover {
            background-color: #f5f5f5;
        }
    }

    img {
        width: 35%;
    }
    
    .info-songs {
        padding: 0.5rem;
        width: 45%;

    }

    .selected {
        color: #567237;
    }

}

.active-library {
    transform: translateX(0%);
    opacity: 1;
}

@media screen and (max-width: 765px){
    .library { 
        width: 100%;

        img {
            width: 30%;
        }
    }
}